import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import config from '../../../../config/config';
import { apiHeaderToken } from '../../../../config/api_header';
import html2pdf from 'html2pdf.js';
import { Modal } from 'react-bootstrap';
import RecordsModal from './ShowApprovalHistory';
import { CircularProgress } from '@mui/material';
import { CamelCases, changeJobTypeLabel, CustomChangesJobType, formatDateToWeekOf, validateTheJobPortal } from '../../../../utils/common';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the Delete icon
import DeleteConfirmationModal from './DeleteMprModal';


function ApprovalTable({ approvalNotes, setMember, setOpenMemberList, setListApproval, getApprovalListByJobId }) {
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState(null);
    const [openModalApprovalHistory, setApprovalHistory] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false)
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteData, setDeleteData] = useState(null);


    let rows = approvalNotes && approvalNotes?.length > 0 ?
        approvalNotes?.map((item, index) => {
            return {
                id: index + 1,
                approvalId: item?.approval_note_id,
                addedDateTime: item?.add_date,
                numberOfCandidates: item?.no_of_candidates,
                approvalStatus: item?.status,
                data: item
            }
        }) : []

    const handleDownload = (data) => {

        setPdfLoading(true)

        const rows = data?.interviewer_list?.length > 0
            ? [...new Map(data.interviewer_list.map(item => [item.emp_doc_id, item])).values()]
                .map((item, index) => `
                  <tr>
                      <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">${index + 1}</td>
                      <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">${item.name}</td>
                      <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">${item.designation}</td>
                      <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">${data.interview_type === 'Online' ? 'Virtual' : 'On-site'} Interview</td>
                  </tr>
              `).join('')
            : `<tr>
              <td colSpan="4" style="text-align:center;">Record Not Found</td>
           </tr>`;
        const Candidates = data?.candidate_list?.length > 0
            ? data.candidate_list.map((item, index) => `
            <tr>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${index + 1}</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${item?.name}</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${data?.job_designation || '-'}</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${ item && item?.proposed_location ? item?.proposed_location : 'Noida' }</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">Rs. ${item?.offer_ctc || 'N/A'}/- per ${item?.payment_type ? item?.payment_type : "Annum" }</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${formatDateToWeekOf(item?.onboarding_date) || '-'}</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${moment(item?.job_valid_date).format("DD-MM-YYYY") || '-'} or till the completion of project</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${CamelCases(CustomChangesJobType(item?.job_type) || '-')}</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${item?.interview_shortlist_status === 'Waiting' ? 'Waitlisted' : item?.interview_shortlist_status || '-'}</td>
                <td style="border: 1px solid #000; font-size: 14px; padding: 8px; text-align: left; font-weight: 500;">${data?.mpr_offer_type === 'new' ? CamelCases(data?.mpr_offer_type) + " " + "Position" : CamelCases(data?.mpr_offer_type) || '-'}</td>
            </tr>
        `).join('')
            : `<tr>
            <td colspan="10" style="text-align: center; border: 1px solid #000;">Record Not Found</td>
           </tr>`

        const styledHtml = `<!DOCTYPE html>
        <html>
        <head>
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Approval Note</title>
            <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap" rel="stylesheet">
        </head>
        <body style="font-family: 'Poppins', sans-serif; color: #000;">
            <table style="width:700px; max-width:700px; margin: 0 auto;" center>
                <tr>
                    <td>
                        <table style="padding:10px; width:100%; border-bottom:1px solid #34209B;">
                            <tr>
                                <td style="text-align: left; padding:10px;">
                                    <img src="https://hlfppt.org/wp-content/themes/hlfppt/images/logo.png">
                                </td>

                                <td style="float: right;">
                                    <table style="padding:0px; width:100%;">
                                        <tr>
                                            <td style="text-align:center;">
                                            <table style="border-collapse: collapse;">
                                                <tr>
                                                    <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Project</td>
                                                    <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;"> ${data && CamelCases(data?.mpr_fund_type)} </td>
                                                </tr>
                                                <tr>
                                                    <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;"> Job Portal </td>
                                                    <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">(${data && CamelCases(validateTheJobPortal(data?.applied_from)?.join(','))})</td>
                                                </tr>
                                            </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:end; width:56%"> 
                                    <h4 style="margin-top:0; width:100%; margin-bottom:0;">Approval Note</h4>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                <td>
                <p style="text-align: right; font-weight: 600;">
                    Date: ${data && moment(data?.approval_date ? data?.approval_date : data?.add_date).format("MMMM DD, YYYY")}
                </p>
                </td>      		
            </tr>
                <tr>
                    <td>
                <p>
                  It has been proposed to appoint following candidate(s) is/are (selected by the undermentioned panel) as per the details given below:
                </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p style="text-decoration:underline;color: #000; font-weight:600">Interviewer Panel List</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table style="width:100%;border-collapse: collapse;">
                                    <tr>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Sr. No.</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Name</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Designation</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Mode of interview</td>
                                    </tr>
                        ${rows}
                        </table>
                    </td>
                </tr>
            <tr>
                <td>
                   <p>
                    The shortlisted candidate(s) was/ were also reviewed by CEO. Based on the evaluation of candidate(s) interviewed, the below mentioned candidate(s) is/ are proposed for selection.
                   </p>
                </td>
            </tr>
                <tr>
                    <td>
                        <p style="text-decoration:underline;color: #000; font-weight:600">Candidates List for the “${data && data?.project_name}” </p>
                    </td>
                </tr>
                <tr>
                    <td>
                            <table style="width:100%;border-collapse: collapse;">
                                    <tr>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Sr. No.</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Name</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Designation</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Proposed Location</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Proposed CTC per ${data?.candidate_list[0]?.payment_type ? data?.candidate_list[0]?.payment_type : "Annum"}</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Proposed Date of joining</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Contract Period (UPTO)</td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;">Employment Nature </td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;"> Status </td>
                                        <td style="border:1px solid #000;font-size:14px;padding:8px;text-align:left; font-weight:500;"> Type of Position </td>
                                    </tr>
                        ${Candidates}
                            </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>Submitted for your kind approval, please</p>
                    </td>
                </tr>
             <tr> 
                <td><strong style="text-decoration: underline;">Recommended By</strong></td>
             </tr>
                <tr>
                    <td style="">
                        ${generatePanelMembersTable(data)}
                    </td>
                </tr>	
            <tr> 
                  <td><strong style="text-decoration: underline;">Approved By</strong></td>
            </tr>
            <tr>
                <td style="">
                        ${generatePanelMembersTableCEO(data)}
                    </td>
            </tr>
            </table>
        </body>
        </html>`;

        openPrintView(styledHtml)
    };

    // generate Panel Member List

    const generatePanelMembersTable = (approvalDetails) => {
        if (!approvalDetails || !approvalDetails?.panel_members_list?.length) {
            return `<table style="padding: 20px 0; width: 100%;">
                            <tbody>
                                <tr>
                                    <td style="text-align: center; font-size: 14px; color: #000;">No Panel Members Found</td>
                                </tr>
                            </tbody>
                        </table>`;
        }

        const rows = approvalDetails.panel_members_list
            .filter((item) => item.designation !== 'CEO' && item.emp_doc_id !== 'NA')
            .sort((a, b) => a.priority - b.priority)
            .reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / 2);
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [];
                }
                resultArray[chunkIndex].push(item);
                return resultArray;
            }, [])
            .map((row, rowIndex) => `
                    <tr key=${rowIndex} style="margin-bottom: 5px; display: flex; justify-content: space-between;">
                        ${row.map((item, colIndex) => `
                            <td style="padding: 10px 20px; text-align: start;">
                                ${item?.signature && !["", "Pending"].includes(item?.approval_status) ? `
                                    <span style="font-size: 15px; display: block; text-align: ${colIndex === 0 ? 'start' : 'end'};">
                                        <img src="${config.IMAGE_PATH + item?.signature}" alt="signature" height="50" width="100" />
                                    </span>` : `
                                    <span style="font-size: 15px; display: block; height: 50px; width: 60px; text-align: ${colIndex === 0 ? 'start' : 'end'};"></span>`
                                }
                                <span style="font-size: 14px; color: #000; display: block; font-weight: 600; text-align: ${colIndex === 0 ? 'start' : 'end'};">
                                    ${item?.designation === 'CEO' ? "Sharad Agarwal" : item?.name}
                                </span>
                                <span style="font-size: 15px; display: inline-block; text-align: ${colIndex === 0 ? 'start' : 'end'};">
                                    ${item?.designation === 'CEO' ? "Chief Executive Officer" : item?.designation}
                                </span>
                            </td>
                        `).join('')}
                    </tr>
                `).join('');

        return `
                <table style="padding: 20px 0; width: 100%;">
                    <tbody>
                        ${rows}
                    </tbody>
                </table>
            `;
    };
    const generatePanelMembersTableCEO = (approvalDetails) => {
        if (!approvalDetails || !approvalDetails?.panel_members_list?.length) {
            return `<table style="padding: 20px 0; width: 100%;">
                            <tbody>
                                <tr>
                                    <td style="text-align: center; font-size: 14px; color: #000;">No Panel Members Found</td>
                                </tr>
                            </tbody>
                        </table>`;
        }

        const rows = approvalDetails.panel_members_list
            .filter((item) => item.designation === 'CEO' && item.emp_doc_id === 'NA')
            .reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / 2); // Two members per row
                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = [];
                }
                resultArray[chunkIndex].push(item);
                return resultArray;
            }, [])
            .map((row, rowIndex) => `
                    <tr key=${rowIndex} style="margin-bottom: 5px; display: flex; justify-content: space-between;">
                        ${row.map((item, colIndex) => `
                            <td style="padding: 10px 20px; text-align: start;">
                               ${item?.signature && !["", "Pending"].includes(item?.approval_status) ? `
                                    <span style="font-size: 15px; display: block; text-align: ${colIndex === 0 ? 'start' : 'end'};">
                                        <img src="${config.IMAGE_PATH + item?.signature}" alt="signature" height="50" width="100" />
                                    </span>` : `
                                    <span style="font-size: 15px; display: block; height: 50px; width: 60px; text-align: ${colIndex === 0 ? 'start' : 'end'};"></span>`
                                }
                                <span style="font-size: 14px; color: #000; display: block; font-weight: 600; text-align: ${colIndex === 0 ? 'start' : 'end'};">
                                    ${item?.name === '' ? "Respected Sir" : item?.name}
                                </span>
                                <span style="font-size: 15px; display: inline-block; text-align: ${colIndex === 0 ? 'start' : 'end'};">
                                    ${item?.designation === 'CEO' ? "Chief Executive Officer" : item?.designation}
                                </span>
                            </td>
                        `).join('')}
                    </tr>
                `).join('');

        return `
                <table style="padding: 20px 0; width: 100%;">
                    <tbody>
                        ${rows}
                    </tbody>
                </table>
            `;
    };

    function openPrintView(htmlContent) {
        // Create a new window
        setPdfLoading(false)
        const printWindow = window.open('', '_blank', 'width=800,height=600');
        // Write the content to the new window
        printWindow.document.open();
        printWindow.document.write(htmlContent);
        printWindow.document.close();

        // Wait for the content to load, then trigger print
        printWindow.onload = () => {
            printWindow.print();
        };
    }


    const handleViewHistory = (data) => {
        setData(data)
        setApprovalHistory(true);
    };

    const handleClosedShowApprovalHistory = () => {
        setApprovalHistory(false);
        setData(null)
    }

    const handleResendApproval = (data) => {
        setMember({ approval_note_doc_id: JSON.parse(JSON.stringify(data))?._id })
        setOpenMemberList(true);
    };

    const columns = [
        {
            field: 'id',
            headerName: 'Sr. No.',
            flex: 0.3, // Adjust flex instead of width for responsiveness
        },
        {
            field: 'approvalId',
            headerName: 'Approval Id',
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="cell-with-ellipsis">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'addedDateTime',
            headerName: 'Added Date/Time',
            width: 220,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="cell-with-ellipsis">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'numberOfCandidates',
            headerName: 'Number of Candidates',
            width: 150,
        },
        {
            field: 'approvalStatus',
            headerName: 'Approval Status',
            width: 150,
        },
        {
            field: 'download',
            headerName: 'Download',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={pdfLoading ? <CircularProgress size={16} color="inherit" /> : <DownloadForOfflineIcon />}
                    onClick={() => handleDownload(params?.row?.data)}
                    disabled={pdfLoading}
                >
                    {pdfLoading ? "Dowloading...." : "Download"}
                </Button>
            ),
        },
        {
            field: 'viewHistory',
            headerName: 'View History',
            width: 150,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleViewHistory(params?.row?.data)}
                >
                    View History
                </Button>
            ),
        },
        {
            field: 'resendApproval',
            headerName: 'Resend Approval',
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => handleResendApproval(params?.row?.data)}
                >
                    Resend Approval
                </Button>
            ),
        },
        {
            field: 'deleteMPR',
            headerName: 'Delete Approval',
            width: 150,
            renderCell: (params) => (
                (
                    params?.row?.data?.status === 'Inprogress' &&
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleOpenDeleteConfirmation(params?.row?.data)}
                    >
                        Delete
                    </Button>
                )
            ),
        }
    ];

    const closedDelete = () => setOpenDelete(false);

    // Handle Open confirmation Modal to Delete the MPR Approval Form -
    const handleOpenDeleteConfirmation = (data) => {
        setDeleteData(data)
        setOpenDelete(true)
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>
                <style>
                    {`
                    .cell-with-ellipsis {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                    }
                `}
                </style>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pagination
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20, 30, 50, 75, 100]}
                    rowHeight={80}
                    autoHeight // Automatically adjusts height based on content
                    disableColumnMenu // Removes unnecessary column menu for a cleaner UI
                />
            </div>

            <RecordsModal openModalApprovalHistory={openModalApprovalHistory} handleClosedShowApprovalHistory={handleClosedShowApprovalHistory} data={data} setData={setData} getApprovalListByJobId={getApprovalListByJobId} />
            <DeleteConfirmationModal open={openDelete} handleClose={closedDelete} data={deleteData} rows={rows} setListApproval={setListApproval} approvalNotes={approvalNotes} />
        </>
    )
}

export default ApprovalTable;
