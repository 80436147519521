import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { CiSearch } from "react-icons/ci";
import JobCandidateTable from "./JobCandidatePanel.js";
import InterviewTable from "./InterviewTables.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ShortListCandidates } from "../../slices/JobSortLIstedSlice/SortLIstedSlice.js";
import { FetchAppliedCandidateDetails } from "../../slices/AppliedJobCandidates/JobAppliedCandidateSlice.js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Offer_table from "./OfferHireRejectTables.js";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from "axios";
import config from "../../../config/config.js";
import { apiHeaderToken } from "../../../config/api_header.js";
import { Button as MuiButton } from '@mui/material';
import ApprovalIcon from '@mui/icons-material/Approval';
import ApprovalTable from "./ApprovalNoteTables/ApprovalTable.js";
import ApprovalModalApprovalCandidate from "./ApprovalNoteTables/ApprovalModal.js";





const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: state.isFocused ? '#D2C9FF' : state.isHovered ? '#80CBC4' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 1px #D2C9FF' : 'none',
        '&:hover': {
            borderColor: '#D2C9FF',
        },
        height: '44px',
        width: '200px'
    }),
    menu: (provided) => ({
        ...provided,
        borderTop: '1px solid #D2C9FF',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e0e0e0' : '#fff', // Focused option styling
        color: state.isFocused ? '#000' : '#333',
        cursor: 'pointer',
        padding: 10,
        fontWeight: state.isFocused ? 'bold' : 'normal',
        border: state.isFocused ? '1px solid #007bff' : 'none',
        textAlign: 'center',
        borderRadius: '4px',
        backgroundColor: state.isFocused ? '#007bff' : '#fff', // Button-like style
        color: state.isFocused ? '#fff' : '#007bff',           // Button-like text color
    }),

};



export default function JobCardsCandidateTabs() {
    const [QueryParams] = useSearchParams();
    const [batchId, setBatchId] = useState(null);
    const type = QueryParams.get('type');
    const [activeTab, setActiveTab] = useState(type === 'new' ? 'first' : type === 'upcomming' ? 'third' : type === 'Shortlisted' ? 'second' : type === 'Interview' ? "third" : type === 'Rejected' ? 'six' : type === 'Hired' ? 'five' : type === 'Offered' ? 'four' : type === 'approval-total' || type === 'approval-pending'  ? "approval" : 'zero');
    const AppliedCandidates = useSelector((state) => state.appliedJobList.AppliedCandidate);
    const filterJobDetails = useSelector((state) => state.appliedJobList.selectedJobList);
    const resetTheValue = useSelector((state) => state.appliedJobList.resetTheValue);
    const getEmployeeRecords = JSON.parse(localStorage.getItem('admin_role_user') ?? {})
    const [CandidatesDetials, setCandidatesDetials] = useState([]);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const [BatchId, setNewBatchId] = useState('')
    const [isActive, setIsActive] = useState(true);
    const { id } = useParams();
    const [memberData , setMember] = useState(null);
    const [openMemberList , setOpenMemberList] = useState(false);
    const [approvalNotes , setListApproval] = useState(null);
    const navigation = useNavigate()

    const getStatus = (key) => {
        switch (key) {
            case 'first': return 'Applied';
            case 'zero': return '';
            case 'second': return 'Shortlisted';
            case 'third': return 'Interview';
            case 'four': return 'Offer';
            case 'five': return 'Hired';
            case 'six': return 'Rejected';
            case 'approval': return 'approval';
            default: return '';
        }
    };


    const [options, setOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const handleCreateOption = (inputValue) => {
        if (/^\d+$/.test(inputValue)) {
            const newOption = { label: inputValue, value: inputValue };
            setBatchId(newOption);
            setNewBatchId(inputValue)
            setShowModal(true);
        } else {
            toast.warn("Invalid input, only numbers are allowed.")
        }
    };


    const GetBatchId = async (input) => {
        try {
            let payloads = { "keyword": input ? input : '', "page_no": "1", "per_page_record": "10", "scope_fields": ["_id", "batch_id"] };
            let response = await axios.post(`${config.API_URL}getBatchIDList`, payloads, apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                return response.data?.data.map((item) => ({
                    value: item._id,
                    label: `${item.batch_id}`,
                }));
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    };

    const loadOptions = async (inputValue, callback) => {
        const fetchedOptions = await GetBatchId(inputValue);
        callback(fetchedOptions); // Provide the options to the select component
    };

    const handleMenuOpen = async () => {
        const fetchedOptions = await GetBatchId('');
        setOptions(fetchedOptions);
    }

    const handleSelect = (key) => {
        setActiveTab(key);
    };

    const formatCreateLabel = (inputValue) => {
        return `➕ Add Batch ID: "${inputValue}"`;
    };

    const handleClose = () => setShowModal(false);
    const [loading, setLoading] = useState(false);
    const [bulkSchedule , setBulkSchedule] = useState(null);

    const handleAddBatchId = async (e) => {
        e.preventDefault();
        try {
            if (!BatchId) {
                return toast.warn('Please Select the Batch id');
            }
            setLoading(true);
            let payloads = { "batch_id": BatchId, "status": "Active" }
            let response = await axios.post(`${config.API_URL}addBatchID`, payloads, apiHeaderToken(config.API_TOKEN))
            if (response.status === 200) {
                setLoading(false)
                toast.success(response.data?.message)
                handleClose();
                setBatchId([]);
            } else {
                toast.error(response.data?.message)
                setLoading(false)
            }
        } catch (error) {
            toast.error(error.response.data.message)
            setLoading(false)
        }
    }

    const ScheduleBulkInterview = (e) => {
        e.preventDefault();
        if (!id && !filterJobDetails) {
            setBulkSchedule('')
            return toast.warning('Please select job first');
        }

        if (CandidatesDetials.length === 0) {
            setBulkSchedule('')
            return toast.warning('No Candidates Selected to Schedule Interview')
        }

        if (e.target.value && e.target.value === 'interview') {
            let candidateIds = [];
            let appliedJobIds = [];
        
            CandidatesDetials.forEach((value) => {
                if (value?.candidateInfo) {
                    candidateIds.push(value.candidateInfo.candidate_id);
                    appliedJobIds.push(value.candidateInfo.applied_job_id);
                }
            });

            let url = `/schedule-interview/${id || filterJobDetails?.value}?userId=${candidateIds}&applied-job-id=${appliedJobIds}&project_id=${CandidatesDetials[0]?.project_id}`

            setTimeout(() => {
                // navigation(url)
                window.location.href = url; // Redirects to the given URL
                
            }, 1000);
        }

        setBulkSchedule(e.target.value)

    }

    const handleBulkSortListed = (e) => {
        if (!batchId) {
            return toast.error('Please select batch first');
        }

        if (!id && !filterJobDetails) {
            return toast.warning('Please select job first');
        }
        e.preventDefault()
        if (CandidatesDetials.length === 0 && e.target.value === 'Shortlisted') {
            return toast.warning('No Candidates Selected to Shortlist')
        }
        if (e.target.value && e.target.value === 'Shortlisted') {
            let candidateDetails = CandidatesDetials.reduce((acc, value) => {
                if (value?.candidateInfo) {
                    acc.push({
                        candidate_id: value?.candidateInfo?.candidate_id,
                        applied_job_id: value?.candidateInfo?.applied_job_id,
                    })
                }
                return acc
            }, [])
            let payloads = {
                "role_user_id": getEmployeeRecords?._id,
                "candidate_ids": candidateDetails,
                "status": "Shortlisted",
                "batch_id": batchId?.label
            }
            dispatch(ShortListCandidates(payloads))
                .unwrap()
                .then((response) => {
                    if (response.status) {
                        dispatch(FetchAppliedCandidateDetails(id || filterJobDetails?.value));
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const handleBulkMarkAsSelectedOrWait = (e) => {
        if (!id && !filterJobDetails) {
            return toast.warning('Please select job first');
        }

        if (CandidatesDetials.length === 0) {
            return toast.warning('No Candidates Selected to Shortlist')
        }
        let candidateDetails = CandidatesDetials.reduce((acc, value) => {
            if (value?.candidateInfo) {
                acc.push(value?.candidateInfo?.candidate_id)
            }
            return acc
        }, [])

        let payload = {
            "approval_note_doc_id": "",
            "job_id": id ? id :  filterJobDetails?.value,
            "project_id": id ? AppliedCandidates?.data?.[0]?.project_id : filterJobDetails?.project_id,
            "candidate_ids": candidateDetails,
            "add_by_name": getEmployeeRecords?.name,
            "add_by_mobile": getEmployeeRecords?.mobile_no,
            "add_by_designation": getEmployeeRecords?.designation,
            "add_by_email": getEmployeeRecords?.email
        }

        axios.post(`${config.API_URL}getJobOfferApprovalMemberList`, payload, apiHeaderToken(config.API_TOKEN))
            .then((res) => {
                if (res.status === 200) {
                    setMember(res.data?.data)
                    setOpenMemberList(true);
                    if(filterJobDetails?.value){
                        getApprovalListByJobId(filterJobDetails?.value)
                    }
                    if(id){
                        getApprovalListByJobId(id)
                    }
                } else {
                    toast.error(res.data?.message)
                }
            })
            .catch(err => {
                toast.error(err?.response?.data?.message || err?.message || "Someting Went Wrong");
            })
    }

    const getApprovalListByJobId = async (jobId = '') => {
        // const [approvalNotes , setListApproval] = useState(null);
        let payload = {
            "job_id": jobId,
            "page_no":1,
            "per_page_record":1000,
            "scope_fields":[],
            "status":type === 'approval-pending'?'Inprogress':''
        }

        try {
            let response = await axios.post(`${config.API_URL}getApprovalNoteFromList` , payload , apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                setListApproval(response.data?.data);
            }else {
                setListApproval([]);
            }
        } catch (error) {
            setListApproval([]);
        }
    }

    useEffect(() => {
        if(filterJobDetails?.value){
            getApprovalListByJobId(filterJobDetails?.value)
        }
        else if(id){
            getApprovalListByJobId(id)
        }else {
            getApprovalListByJobId()
        }
    } , [ filterJobDetails , id])

    useEffect(() => {

        if(resetTheValue){
            setListApproval([])
        }

    } , [resetTheValue])

    const status = getStatus(activeTab);
    return (
        <>
            <Tab.Container id="left-tabs-example" className="" defaultActiveKey={type === 'new' ? 'first' : type === 'upcomming' ? 'third' : type === 'Shortlisted' ? 'second' : type === 'Interview' ? "third" : type === 'Rejected' ? 'six' : type === 'Hired' ? 'five' : type === 'Offered' ? 'four' : type === 'approval-total' || type === 'approval-pending'  ? "approval" : 'zero'} onSelect={handleSelect}>
                <Nav variant="pills" className="flex-row postedjobs border-full mb-4 widthcomp widthfuller">
                    <Nav.Item>
                        <Nav.Link eventKey="zero">Total Candidates ({AppliedCandidates.status === 'success' && AppliedCandidates.data?.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="first">New Candidates ({AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Applied'))?.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second">Shortlisted ({AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Shortlisted'))?.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="third">Interview ({AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Interview'))?.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="approval">Approval Note ({approvalNotes ? approvalNotes?.length : 0})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="four">Offer ({AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Offer'))?.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="five">Hired ({AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Hired'))?.length})</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="six">Rejected ({AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Rejected'))?.length})</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="zero">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="tbl_hdng">
                                <h6>{AppliedCandidates.status === 'success' && AppliedCandidates.data?.length} Candidates</h6>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate or Project Name"
                                        aria-label="Username"
                                        value={search}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <JobCandidateTable PageStatus={status} setCandidatesDetials={setCandidatesDetials} filterText={search} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="first">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="tbl_hdng">
                                <h6>{AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Applied'))?.length} Candidates</h6>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        value={search}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start bulkaction_btn mb-3">
                            {/* Here is Have the Createbal Input Box if Data is not match becode the input  */}
                            <div className="d-flex flex-row gap-3 me-2">
                                <AsyncCreatableSelect
                                    isClearable
                                    defaultOptions={options}
                                    value={batchId}
                                    loadOptions={loadOptions}
                                    onChange={(newValue) => setBatchId(newValue)}
                                    onCreateOption={handleCreateOption}
                                    placeholder="Select or create..."
                                    onMenuOpen={handleMenuOpen}
                                    formatCreateLabel={formatCreateLabel}
                                    styles={customStyles}
                                />
                            </div>
                            <Form.Select aria-label="Default select example" onChange={handleBulkSortListed}>
                                <option value={null}>Bulk Action</option>
                                <option value="Shortlisted">Shortlist</option>
                            </Form.Select>
                        </div>
                        <JobCandidateTable PageStatus={status} setCandidatesDetials={setCandidatesDetials} filterText={search} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="tbl_hdng">
                                <h6>{AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Shortlisted'))?.length} Candidates</h6>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        value={search}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start bulkaction_btn mb-3">
                            <Form.Select aria-label="Default select example" value={bulkSchedule} onChange={ScheduleBulkInterview}>
                                <option value={null}>Bulk Action</option>
                                <option value="interview">Schedule Bulk Interview</option>
                            </Form.Select>
                        </div>
                        <JobCandidateTable PageStatus={status} setCandidatesDetials={setCandidatesDetials} filterText={search} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="d-flex gap-4">
                                <h5 className="m-0">
                                    <span className="text-nowrap">
                                        {AppliedCandidates.status === 'success' &&
                                            AppliedCandidates.data.filter(
                                                (key) =>
                                                    key?.applied_jobs?.some(item => item?.form_status === 'Interview')
                                            )?.length}{' '}
                                        Candidates
                                    </span>
                                </h5>
                                <MuiButton
                                    variant="contained"
                                    color="success" // Set the button color to 'success'
                                    startIcon={<ApprovalIcon />}
                                    onClick={() => handleBulkMarkAsSelectedOrWait()}
                                    disabled={CandidatesDetials.length === 0}
                                >
                                    Approval Note
                                </MuiButton>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        value={search}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <InterviewTable PageStatus={status} filterText={search} setCandidatesDetials={setCandidatesDetials} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="approval">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="d-flex gap-4">
                                <h5 className="m-0">
                                    <span className="text-nowrap">
                                        {approvalNotes ? approvalNotes?.length : 0} {" "}
                                        Approval Note
                                    </span>
                                </h5>
                            </div>
                            {/* <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        value={search}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div> */}
                        </div>
                        <ApprovalTable approvalNotes={approvalNotes} setMember={setMember} setOpenMemberList={setOpenMemberList} setListApproval={setListApproval} getApprovalListByJobId={getApprovalListByJobId} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="tbl_hdng">
                                <h6>{AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Offer'))?.length} Candidates</h6>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <Offer_table PageStatus={status} setCandidatesDetials={setCandidatesDetials} filterText={search} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="tbl_hdng">
                                <h6>{AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Hired'))?.length} Candidates</h6>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        <Offer_table PageStatus={status} setCandidatesDetials={setCandidatesDetials} filterText={search} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">
                        <div className="d-flex justify-content-between align-items-end mb-4">
                            <div className="tbl_hdng">
                                <h6>{AppliedCandidates.status === 'success' && AppliedCandidates.data.filter((key) => key?.applied_jobs?.some(item => item?.form_status === 'Rejected'))?.length} Candidates</h6>
                            </div>
                            <div className="d-flex flex-row gap-3">
                                <InputGroup className="searchy-input">
                                    <InputGroup.Text id="basic-addon1" className="border-0">
                                        <CiSearch />
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Search Candidate"
                                        aria-label="Username"
                                        value={search}
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <Offer_table PageStatus={status} setCandidatesDetials={setCandidatesDetials} filterText={search} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>


            {/* Show the Add Batch Id Models here */}
            {/* Modal for adding a batch */}
            {/* Modal for adding a batch */}
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Batch ID</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Input for Batch ID */}
                        <Form.Group controlId="batchId">
                            <Form.Label>Batch ID</Form.Label>
                            <Form.Control
                                type="text"
                                value={BatchId}
                                onChange={(e) => setNewBatchId(e.target.value)}
                                placeholder="Enter Batch ID"
                            />
                        </Form.Group>

                        {/* Checkbox for Status */}
                        <Form.Group controlId="batchStatus" className="mt-3">
                            <Form.Check
                                type="checkbox"
                                label="Active"
                                checked={isActive}
                                readOnly
                            />
                            <Form.Text className="text-muted">
                                Uncheck to set as Inactive.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {
                        loading ?
                            <Button variant="primary">
                                <Spinner animation="border" variant="primary" />
                            </Button>
                            :
                            <Button variant="primary" onClick={handleAddBatchId}>
                                Add Batch ID
                            </Button>
                    }
                </Modal.Footer>
            </Modal>

            {/* setOpen Member List to send Email and Update the Existing member with Modla - */}
            <ApprovalModalApprovalCandidate open={openMemberList} setOpen={setOpenMemberList} memberListData={memberData} approvalNotes={approvalNotes}/>
        </>
    )
}

