

import logo from '../images/logo.png';

let user = JSON.parse(localStorage.getItem('admin_role_user')) || {};

let config = {}
config['API_TOKEN'] = `${user?.token}`;


//Live
// config['BASE_URL'] = 'https://admin-hrms.dtsmis.in/';
// config['IMAGE_PATH'] = 'https://api-hrms.dtsmis.in:3008/public/uploads/';
// config['API_URL']='https://api-hrms.dtsmis.in:3008/v1/employee/';

// // / duplex tech URL 
config['BASE_URL'] = 'https://hrms.duplextech.com:3018/';
config['API_URL'] = ' https://apis.duplextech.com:3018/v1/employee/';
config['GLOB_API_URL'] = 'https://apis.duplextech.com:3018/v1/global/';
config['IMAGE_PATH'] = 'https://apis.duplextech.com:3018/public/uploads/';
config['CANDIDATE_URL'] = 'https://apis.duplextech.com:3018/v1/candidate/';
config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
config['FRONT_URL'] = ' https://career.duplextech.com/';
config['LOGO_PATH'] = 'https://res.cloudinary.com/duplex-tech/image/upload/v1689317653/newDuplex/setting/eviirl8exl9agdov3ej6.png'





// config['LOGO_PATH'] = logo;

config['COMPANY_NAME'] = 'HRMS WEB APP';  
config['PANEL_NAME'] = 'HR Login';
config['HELP_URL'] = 'support';
config['PRIVACY_URL'] = 'privacy-policy';
config['TERMS_URL'] = 'terms-conditions';
config['COMPANY_NAME'] = 'HLFPPT HRMS';

// // Live Server
// config['BASE_URL'] = 'https://hrapi.hlfppt.org/';
// config['API_URL'] = 'https://hrapi.hlfppt.org/v1/employee/';
// config['PANEL_URL'] = 'https://hrapi.hlfppt.org/'
// config['IMAGE_PATH'] = 'https://hrapi.hlfppt.org/public/uploads/';
// config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';
// config['GLOB_API_URL'] = 'https://hrapi.hlfppt.org/v1/global/';

export default config;